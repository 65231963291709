import React from 'react';

import { Button, ButtonPriority, TextArea } from 'wix-ui-tpa';

import { DATA_HOOKS } from './constants';
import { st, classes } from './DeclineGroupRequestModal.st.css';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { Modal } from '../../../../../common/components/Modal/Modal';
import { Theme, withTheme } from '../../../../../common/context/theme';

export interface DeclineGroupRequestModalProps {
  creatorName: string;

  onConfirmGroupRejection(rejectionReason: any): void;

  onRequestClose(e: any): void;
}

interface DeclineGroupRequestModalState {
  rejectionReason: string;
}

export class DeclineGroupRequestModalComponent extends React.Component<
  WithTranslation & DeclineGroupRequestModalProps & Theme,
  DeclineGroupRequestModalState
> {
  readonly state: DeclineGroupRequestModalState = {
    rejectionReason: '',
  };

  handleRejectionReasonChange = (e: any) => {
    this.setState({
      rejectionReason: e.target.value,
    });
  };

  handleOnSubmit = (e: any) => {
    e.preventDefault();
    const { rejectionReason } = this.state;
    this.props.onConfirmGroupRejection(rejectionReason);
  };

  render() {
    const { t, creatorName, onRequestClose, forceBlackAndWhite } = this.props;

    return (
      <form
        data-hook={DATA_HOOKS.FORM}
        onSubmit={this.handleOnSubmit}
        className={st(classes.root, { bw: !!forceBlackAndWhite })}
      >
        <Modal.Header
          title={t('groups-web.modal.decline-group.title')}
          subtitle={t('groups-web.modal.decline-group.subtitle', {
            memberName: creatorName,
          })}
        />
        <TextArea
          data-hook={DATA_HOOKS.REJECTION_REASON_INPUT}
          placeholder={t(
            'groups-web.modal.decline-group.rejectionReason.placeholder',
          )}
          onChange={this.handleRejectionReasonChange}
          value={this.state.rejectionReason}
          ariaLabel={t(
            'groups-web.modal.decline-group.rejectionReason.placeholder',
          )}
          className={classes.rejectionReasonInput}
        />
        <Modal.Buttons>
          <Button
            className={classes.cancelButton}
            priority={ButtonPriority.secondary}
            onClick={onRequestClose}
          >
            {t('groups-web.modal.decline-group.action.cancel')}
          </Button>
          <Button
            className={classes.declineButton}
            type="submit"
            priority={ButtonPriority.primary}
          >
            {t('groups-web.modal.decline-group.action.decline')}
          </Button>
        </Modal.Buttons>
      </form>
    );
  }
}

export const DeclineGroupRequestModal = withTheme(
  withTranslation()(DeclineGroupRequestModalComponent),
);
