import React from 'react';
import { Text, TextTypography } from 'wix-ui-tpa';
import { useGroupRequests } from '@wix/social-groups-api';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { classes } from './PendingGroupList.st.css';
import { ExpandableContainer } from 'common/components/Container/ExpandableContainer';
import { useUser } from 'common/context/user/useUser';
import { Member } from 'common/api/model/Member/Member';
import { Card } from 'common/components/GroupCard/v2/Card';
import { PendingGroupMeta } from 'Groups/Widget/PendingGroupList/PendingGroupMeta';
import { OwnerActions } from 'Groups/Widget/PendingGroupList/OwnerActions';
import { CreatorActions } from 'Groups/Widget/PendingGroupList/CreatorActions';
import { GROUP_REQUESTS } from 'Groups/Widget/PendingGroupList/dataHooks';
import { PendingGroupsMobile } from 'Groups/Widget/PendingGroupList/PendingGroupsMobile';

interface Props {}

export const GroupRequestsList: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const {
    groupRequestsResponse,
    groupRequestsActions,
    // TODO: groupRequestsStatus,
  } = useGroupRequests();
  const {
    userPermissions: { canResolvePendingGroups },
  } = useUser();
  const total = groupRequestsResponse?.metadata?.total;
  if (!groupRequestsResponse || !total) {
    return null;
  }
  const title = canResolvePendingGroups
    ? 'groups-web.group-list.pending-groups.title'
    : 'groups-web.group-list.pending-groups.creator-title';

  if (isMobile) {
    return <PendingGroupsMobile total={total} />;
  }
  return (
    <article data-hook={GROUP_REQUESTS} key={GROUP_REQUESTS}>
      <ExpandableContainer
        title={
          <Text
            typography={TextTypography.runningText}
            className={classes.title}
          >
            {t(title)}
            <span className={classes.badge}>{total}</span>
          </Text>
        }
        className={classes.root}
      >
        {groupRequestsResponse.groupRequests?.map((groupRequest) => {
          const group = groupRequest.group;
          const createdBy = new Member(
            groupRequestsResponse.creators[group?.createdBy?.id!],
          );
          const groupUrl = groupRequestsResponse.groupUrls[group?.id!];
          return (
            <Card
              layout="pending"
              details={
                <PendingGroupMeta
                  canResolvePendingGroups={!!canResolvePendingGroups}
                  createdBy={createdBy}
                  groupRequest={groupRequest}
                  groupUrl={groupUrl}
                />
              }
              cta={
                canResolvePendingGroups ? (
                  <OwnerActions
                    groupRequest={groupRequest}
                    groupRequestsActions={groupRequestsActions}
                    createdBy={createdBy}
                  />
                ) : (
                  <CreatorActions
                    groupRequest={groupRequest}
                    groupUrl={groupUrl}
                    groupRequestsActions={groupRequestsActions}
                  />
                )
              }
              mobile={isMobile}
            />
          );
        })}
      </ExpandableContainer>
    </article>
  );
};

GroupRequestsList.displayName = 'GroupRequestsList';
