import React from 'react';

import { Button, Text } from 'wix-ui-tpa';

import { st, classes } from './PendingGroupList.st.css';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { MemberInvites } from '../../../../common/context/member-invites/MemberInvites';
import { WebOut } from '../../../../common/components/WebOut/WebOut';
import { compose } from '../../../../common/utils/compose';
import { withMemberInvites } from '../../../../common/context/member-invites/withMemberInvites';

export interface PendingGroupsMobileProps {
  total: number;
}

export interface PendingGroupsMobileState {
  isOpened: boolean;
}

export class PendingGroupsMobileComponent extends React.Component<
  PendingGroupsMobileProps & WithTranslation & MemberInvites,
  PendingGroupsMobileState
> {
  readonly state: PendingGroupsMobileState = {
    isOpened: false,
  };

  openWebOut = () => this.setState({ isOpened: true });

  closeWebOut = () => this.setState({ isOpened: false });

  render() {
    const { t, memberInvitesLink, total, loadMemberInvitesLink } = this.props;
    const { isOpened } = this.state;

    return (
      <>
        <Button
          onClick={this.openWebOut}
          className={st(classes.root, { mobile: true })}
        >
          <Text className={classes.mobileLabel}>
            {t('groups-web.group-list.pending-groups-mobile.label', {
              count: total,
            })}
          </Text>
          &nbsp;
          <Text className={classes.mobileCta}>
            {t('groups-web.group-list.pending-groups-mobile.cta')}
          </Text>
        </Button>
        <WebOut
          inviteLink={memberInvitesLink}
          loadMemberInvitesLink={loadMemberInvitesLink}
          buttonLabelKey={t('groups-web.modal.out.button')}
          title={t('groups-web.modal.out.pending-groups.title')}
          caption={t('groups-web.modal.out.pending-groups.description')}
          isOpen={isOpened}
          onRequestClose={this.closeWebOut}
        />
      </>
    );
  }
}

const enhance = compose(withTranslation(), withMemberInvites);

export const PendingGroupsMobile = enhance(PendingGroupsMobileComponent);
